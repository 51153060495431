import { ContentFile, AppliedContentFilters } from './types';
import { subscribeContentFilters } from './content-filters';
import { subscribeToFirstValue } from './behavior-subject-subscribe-to-first-value';

export type NS_DYNAMIC_REACT_CONTENT_MANAGER = (
  contentFilters: AppliedContentFilters
) => ContentFile | Promise<{ default: ContentFile }>;

type Importer = () => Promise<{ default: ContentFile }> | ContentFile;

const defaultSplit = 'default';

const isPromise = (value: unknown): value is Promise<any> =>
  value && typeof (value as any).then === 'function';

export const createScopedReactContentImporter = (
  splitPoint: string | undefined,
  importers: Record<string, Importer>
): NS_DYNAMIC_REACT_CONTENT_MANAGER => {
  const cache = new Map<Importer, ReturnType<Importer> | ContentFile>();

  const tryImport = (importer: Importer) => {
    if (!cache.has(importer)) {
      const promiseOrContent = importer();
      // Our importer might be able to give us the content right-off
      // if it was inlined. If its a promise, we must await it
      if (isPromise(promiseOrContent)) {
        promiseOrContent.then(
          ({ default: val }) => cache.set(importer, val),
          () => cache.delete(importer)
        );
      }
      cache.set(importer, promiseOrContent);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return cache.get(importer)!;
  };

  const getContentFileForFilters: NS_DYNAMIC_REACT_CONTENT_MANAGER = (
    contentFilters
  ) => {
    let importer = importers[defaultSplit];
    if (splitPoint && splitPoint in contentFilters) {
      importer = importers[String(contentFilters[splitPoint])] || importer;
    }
    return tryImport(importer);
  };

  // This file was just loaded, so we want to immediately start loading the content file
  // given the first non-null filters
  subscribeToFirstValue(subscribeContentFilters, getContentFileForFilters);

  // Return the getter
  return getContentFileForFilters;
};
