export function select(
  value: string | number,
  options: { other: string[]; [key: string]: string[] }
): string[] {
  if (options[value]) {
    return options[value];
  } else {
    return options.other;
  }
}
