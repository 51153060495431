import { createElement, FC, Fragment } from 'react';

import {
  PostMacroComponentsAndExpressions,
  PostMacroDevVariables
} from './types';
import { useContentBase } from './use-content-base-hook';
import { NS_DYNAMIC_REACT_CONTENT_MANAGER } from './content-importer';

interface Props {
  /**
   * the hash id of the Content, shared by all variants of a given Content
   */
  hash: string;

  /**
   * an object map of identiers/memberExpressions aggregated from all variants of a given Content
   */
  devVariables: PostMacroDevVariables;

  /**
   * an array of components/complexExpressions aggregated from all variants of a given Content
   */
  componentsAndExpressions: PostMacroComponentsAndExpressions;

  /**
   * The importer that was created for this source file
   */
  importer: NS_DYNAMIC_REACT_CONTENT_MANAGER;
}

/**
 * The Post-Macro <Content/> component
 */
export const Content: FC<Props> = ({
  hash,
  devVariables,
  componentsAndExpressions,
  importer
}: Props) => {
  // This hook will suspend if needed
  const createContentArray = useContentBase(
    hash,
    devVariables,
    componentsAndExpressions,
    importer
  );

  return createElement(Fragment, null, ...createContentArray);
};
