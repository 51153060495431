import { BehaviorSubjectSubscribe } from 'packages/behavior-subject';

export const subscribeToFirstValue = <T>(
  behaviorSubject: BehaviorSubjectSubscribe<T | null>,
  callback: (value: T) => void
): void => {
  try {
    let needsUnsubscribe = false;
    let unsubscribe: null | (() => void) = null;
    unsubscribe = behaviorSubject((val) => {
      if (val !== null) {
        if (unsubscribe) {
          unsubscribe();
        } else {
          needsUnsubscribe = true;
        }
        callback(val);
      }
    });
    if (needsUnsubscribe) unsubscribe();
  } catch (err) {
    // noop
  }
};
