import { useMemo } from 'react';
import {
  PostMacroComponentsAndExpressions,
  PostMacroDevVariables
} from './types';
import { NS_DYNAMIC_REACT_CONTENT_MANAGER } from './content-importer';
import { useContentBase } from './use-content-base-hook';

export const useContent = (
  hash: string,
  devVariables: PostMacroDevVariables,
  componentsAndExpressions: PostMacroComponentsAndExpressions,
  importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
): string => {
  // This hook will suspend if needed
  const createContentArray = useContentBase(
    hash,
    devVariables,
    componentsAndExpressions,
    importer
  );

  return useMemo(() => {
    for (const contentPiece of createContentArray) {
      if (typeof contentPiece === 'object') {
        throw new Error(
          'Unexpected object in useContent createContentArray: ' +
            JSON.stringify(contentPiece)
        );
      }
    }

    return createContentArray.join('');
  }, [createContentArray]);
};
