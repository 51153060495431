import { BehaviorSubjectSubscribe } from 'packages/behavior-subject';

export const getValue = <T>(
  behaviorSubject: BehaviorSubjectSubscribe<T>
): T => {
  let unsubscribe: undefined | (() => void) = undefined;
  try {
    let value: T | undefined = undefined;
    unsubscribe = behaviorSubject((v) => (value = v));
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return value!;
  } finally {
    if (unsubscribe) {
      unsubscribe();
    }
  }
};
