import { ReactElement } from 'react';

import {
  AppliedContentFilters,
  ContentFile,
  PostMacroComponentsAndExpressions,
  PostMacroDevVariables
} from './types';
import { findMostSpecificVariant } from './find-variant';
import { plural } from './plural';
import { select } from './select';

/**
 * Find the appropriate variant from the `ContentFile` and
 *   return an array populated by strings and react components
 */
export function getContentInReactFormFromVariantHashAndValues(
  contentFile: ContentFile,
  appliedContentFilters: AppliedContentFilters,
  hash: string,
  devVariables: PostMacroDevVariables,
  componentsAndExpressions: PostMacroComponentsAndExpressions
): Array<string | ReactElement<any>> {
  const variants = contentFile['h' + hash];

  if (!variants) {
    throw new Error(`missing variant hash="${hash}"`);
  }

  const specificVariant = findMostSpecificVariant(
    variants,
    appliedContentFilters
  );
  if (!specificVariant) {
    throw new Error('no specific variant');
  }
  return specificVariant[0](devVariables, componentsAndExpressions, {
    plural,
    select
  });
}
