import { subscribeContentFilters } from './content-filters';
import { getValue } from './behavior-subject-get-value';
import { useEffect, useState } from 'react';
import { subscribeToFirstValue } from './behavior-subject-subscribe-to-first-value';

export const useContentFilters = () => {
  // eslint-disable-next-line prefer-const
  let [currentContentFilters, setCurrentContentFilters] = useState(() =>
    getValue(subscribeContentFilters)
  );

  // If we just came out of a suspend, our effect has not yet updated
  // the state and we need to get the current value of the subject instead
  if (currentContentFilters === null) {
    currentContentFilters = getValue(subscribeContentFilters);
  }

  // If it is still null, we need to suspend and wait for filters to be ready
  if (currentContentFilters === null) {
    // Convert the BS to a Promise that resolves when we have content filters
    throw new Promise((resolve) =>
      subscribeToFirstValue(subscribeContentFilters, resolve)
    );
  }

  // Subscribe to updates to the filters
  useEffect(() => subscribeContentFilters(setCurrentContentFilters), []);

  return currentContentFilters;
};
