import {
  AbstractContentFilters,
  AppliedContentFilters,
  ContentFileVariant
} from './types';

export function findMostSpecificVariant(
  variants: ContentFileVariant[],
  appliedContentFilters: AppliedContentFilters
): ContentFileVariant | undefined {
  const matchingVariants: Array<[ContentFileVariant, number]> = [];
  let defaultVariant: ContentFileVariant | undefined;

  variants.forEach((variantChild) => {
    if (Object.keys(variantChild[1]).length === 0) {
      if (!defaultVariant) {
        defaultVariant = variantChild;
        return;
      } else {
        throw new Error('Multiple Default Variants');
      }
    }

    let match = 0;
    let explicitlyDoesntMatch = false;
    Object.entries(appliedContentFilters).forEach((entry) => {
      const [whiteLabelKey, whiteLabelValue] = entry;
      const contentFilters = variantChild[1];
      const variantValue =
        (contentFilters &&
          (contentFilters as AbstractContentFilters)[whiteLabelKey]) ||
        undefined;
      if (typeof variantValue !== 'undefined') {
        if (
          Array.isArray(variantValue) &&
          variantValue.includes(whiteLabelValue as string)
        ) {
          match++;
        } else if (variantValue === whiteLabelValue) {
          match++;
        } else {
          explicitlyDoesntMatch = true;
        }
      }
    });
    if (match > 0 && !explicitlyDoesntMatch) {
      matchingVariants.push([variantChild, match]);
    }
  });

  if (matchingVariants.length > 0) {
    return matchingVariants.sort((variantA, variantB) => {
      if (variantA[1] > variantB[1]) {
        return -1;
      } else if (variantA[1] < variantB[1]) {
        return 1;
      } else {
        // throw error? no variants should have equivilent specificity
        return 0;
      }
    })[0][0];
  } else {
    return defaultVariant;
  }
}
