import { useMemo, ReactElement } from 'react';

import {
  PostMacroComponentsAndExpressions,
  PostMacroDevVariables,
  ContentFile
} from './types';
import { getContentInReactFormFromVariantHashAndValues } from './content-file-to-react';
import { NS_DYNAMIC_REACT_CONTENT_MANAGER } from './content-importer';
import { useContentFilters } from './use-content-filters';

const isPromise = (value: unknown): value is Promise<any> =>
  value && typeof (value as any).then === 'function';

/**
 * This is the main hook that is used to get content out from the system.
 * There are 2 async things that are needed.
 * 1. ContentFilters. These are used to determine which content file to load,
 *   and then which specific piece of content to use from that set of content
 * 2. ContentFile. This is the set of content that is partitioned based on a
 *   specific filter.
 *
 * If the filters are not yet ready, or if the content file is not yet ready
 * then this component will <Suspend> and you must have a Suspense fallback
 * setup above this react tree.
 */
export const useContentBase = (
  hash: string,
  devVariables: PostMacroDevVariables,
  componentsAndExpressions: PostMacroComponentsAndExpressions,
  importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
): Array<string | ReactElement<any>> => {
  // This will suspend if the filters are not ready yet.
  const contentFilters = useContentFilters();

  const contentFile = useMemo(() => importer(contentFilters), [
    importer,
    contentFilters
  ]);

  if (isPromise(contentFile)) {
    // Suspend!
    throw contentFile;
  }

  // Transform the content using our locals into a useable array
  return useMemo(
    () =>
      getContentInReactFormFromVariantHashAndValues(
        contentFile as ContentFile,
        contentFilters,
        hash,
        devVariables,
        componentsAndExpressions
      ),
    [componentsAndExpressions, contentFile, contentFilters, devVariables, hash]
  );
};
